import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Dropdown, Row, Spin, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { initialize, isSubmitting, submit } from 'redux-form'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { ColumnsType, TableProps } from 'antd/es/table'
import cx from 'classnames'

// components
import Breadcrumbs from '../../components/Breadcrumbs'
import CustomTable from '../../components/CustomTable'
import UserAvatar from '../../components/AvatarComponents'
import NotinoReservationsFilter from './components/NotinoReservationsFilter'
import CancelReservationConfirmForm from '../../components/CancelReservationConfirmForm'
import ConfirmModal from '../../atoms/ConfirmModal'

// utils
import {
	ADMIN_RESERVATIONS_ORDER,
	CALENDAR_DATE_FORMAT,
	CALENDAR_EVENT_TYPE,
	CALENDAR_VIEW,
	DEFAULT_DATE_INPUT_FORMAT,
	FORM,
	PERMISSION,
	RESERVATION_FEEDBACK_STATE,
	RESERVATION_STATE,
	ROW_GUTTER_X_DEFAULT,
	STRINGS
} from '../../utils/enums'
import { withPermissions } from '../../utils/Permissions'
import { normalizeDirectionKeys, setOrder, translateReservationPaymentMethod, translateReservationState } from '../../utils/helper'
import { patchReq } from '../../utils/request'

// reducers
import { RootState } from '../../reducers'
import { setSelectedCountry } from '../../reducers/selectedCountry/selectedCountryActions'
import { getNotinoReservations, INotinoReservationsTableData } from '../../reducers/paginatedReservations/paginatedReservationsActions'
import { getCategories } from '../../reducers/categories/categoriesActions'

// types
import { IBreadcrumbs, ICancelReservationConfirmForm, INotinoReservationsFilter } from '../../types/interfaces'
import { ICalendarPageURLQueryParams } from '../../types/schemaTypes'

// hooks
import useQueryParams, { formatObjToQuery } from '../../hooks/useQueryParamsZod'

// schema
import { notinoReservationsQueryParamsSchema } from '../../schemas/queryParams'

// assets
import DotsIcon from '../../assets/icons/more-info-horizontal-icon.svg?react'
import EditIcon from '../../assets/icons/edit-icon.svg?react'
import RefreshIcon from '../../assets/icons/refresh-icon.svg?react'
import CloseIcon from '../../assets/icons/close-circle-icon.svg?react'

type TableDataItem = NonNullable<INotinoReservationsTableData>

const NotinoReservationsPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const notinoReservations = useSelector((state: RootState) => state.paginatedReservations.notinoReservations)
	const navigate = useNavigate()
	const selectedCountry = useSelector((state: RootState) => state.selectedCountry.selectedCountry)
	const [visibleConfirmModalData, setVisibleConfirmModalData] = useState<TableDataItem | undefined>(undefined)
	const isCancelFormSubmitting = useSelector(isSubmitting(FORM.CANCEL_RESERVATION_CONFIRM))

	const loading = notinoReservations?.isLoading

	const [query, setQuery] = useQueryParams(notinoReservationsQueryParamsSchema, {
		page: 1,
		order: ADMIN_RESERVATIONS_ORDER.CREATED_AT_DESC
	})

	const countryCode = selectedCountry || query.countryCode

	useEffect(() => {
		dispatch(getCategories())
	}, [dispatch])

	useEffect(() => {
		dispatch(
			initialize(FORM.NOTINO_RESERVATIONS_FILTER, {
				dateFrom: query.dateFrom,
				dateTo: query.dateTo,
				createdAtFrom: query.createdAtFrom,
				createdAtTo: query.createdAtTo,
				reservationStates: query.reservationStates,
				reservationPaymentMethods: query.reservationPaymentMethods,
				reservationCreateSourceType: query.reservationCreateSourceType,
				search: query.search,
				categoryFirstLevelIDs: query.categoryFirstLevelIDs,
				countryCode
			})
		)
		dispatch(
			getNotinoReservations({
				dateFrom: query.dateFrom,
				dateTo: query.dateTo,
				createdAtFrom: query.createdAtFrom,
				createdAtTo: query.createdAtTo,
				reservationStates: query.reservationStates,
				reservationPaymentMethods: query.reservationPaymentMethods,
				reservationCreateSourceType: query.reservationCreateSourceType,
				categoryFirstLevelIDs: query.categoryFirstLevelIDs,
				countryCode,
				page: query.page,
				order: query.order,
				limit: query.limit,
				search: query.search
			})
		)
	}, [
		dispatch,
		query.categoryFirstLevelIDs,
		query.createdAtFrom,
		query.createdAtTo,
		query.dateFrom,
		query.dateTo,
		query.limit,
		query.page,
		query.reservationCreateSourceType,
		query.reservationPaymentMethods,
		query.reservationStates,
		query.search,
		query.order,
		countryCode
	])

	const handleSubmit = (values: INotinoReservationsFilter) => {
		const newQuery = {
			...query,
			...values,
			page: 1
		}
		// update selected country globally based on filter
		dispatch(setSelectedCountry(values?.countryCode || undefined))
		setQuery(newQuery)
	}

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}` as ADMIN_RESERVATIONS_ORDER

			const newQuery = {
				...query,
				limit: pagination.pageSize,
				page: pagination.current,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === notinoReservations?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	const actionItems = (record: TableDataItem) => [
		{
			key: 'edit',
			label: t('loc:Upraviť rezerváciu'),
			icon: <EditIcon className={'small-icon'} />,
			onClick: () => {
				if (!record.deletedAt && !record.salon.deletedAt) {
					const redirectQuery = {
						view: CALENDAR_VIEW.DAY,
						date: dayjs(record.startDate, DEFAULT_DATE_INPUT_FORMAT).format(CALENDAR_DATE_FORMAT.QUERY),
						sidebarView: CALENDAR_EVENT_TYPE.RESERVATION,
						eventId: record.key
					}
					navigate({
						pathname: t('paths:salons/{{salonID}}/calendar', { salonID: record.salon.id }),
						search: formatObjToQuery(redirectQuery)
					})
				}
			}
		},
		record.state !== RESERVATION_STATE.PENDING
			? {
					key: 'duplicate',
					label: t('loc:Zopakovať rezerváciu'),
					icon: <RefreshIcon className={'small-icon'} />,
					onClick: () => {
						const redirectQuery: ICalendarPageURLQueryParams = {
							view: CALENDAR_VIEW.DAY,
							date: dayjs().format(CALENDAR_DATE_FORMAT.QUERY),
							sidebarView: CALENDAR_EVENT_TYPE.RESERVATION,
							repeatReservationId: record.key
						}
						navigate({
							pathname: t('paths:salons/{{salonID}}/calendar', { salonID: record.salon.id }),
							search: formatObjToQuery(redirectQuery)
						})
					}
				}
			: null,
		// Show only if not deleted or declined
		record.state !== RESERVATION_STATE.CANCEL_BY_SALON &&
		record.state !== RESERVATION_STATE.CANCEL_BY_SYSTEM &&
		record.state !== RESERVATION_STATE.CANCEL_BY_CUSTOMER &&
		record.state !== RESERVATION_STATE.DECLINED
			? {
					key: 'delete',
					label: t('loc:Zrušiť rezerváciu'),
					icon: <CloseIcon className={'small-icon'} />,
					className: 'text-notino-red',
					onClick: () => setVisibleConfirmModalData(record)
				}
			: null
	]

	const handleCancelReservationConfirmForm = async (confirmValues: ICancelReservationConfirmForm) => {
		try {
			const { text, quickFeedbackAnswers } = confirmValues
			if (visibleConfirmModalData) {
				await patchReq('/api/b2b/admin/salons/{salonID}/calendar-events/reservations/{calendarEventID}/state', {
					params: { path: { calendarEventID: visibleConfirmModalData.id, salonID: visibleConfirmModalData.salon.id } },
					reqBody: {
						state: RESERVATION_STATE.CANCEL_BY_SALON,
						paymentMethod: visibleConfirmModalData.paymentMethod,
						reservationCancellation: quickFeedbackAnswers
							? {
									// TODO: odstranit any ked sa opravia typove definicie
									text: (quickFeedbackAnswers === RESERVATION_FEEDBACK_STATE.OWN_MESSAGE && text ? text : undefined) as any,
									quickFeedbackAnswers: [quickFeedbackAnswers]
								}
							: undefined
					}
				})
				// refetch data after update
				dispatch(
					getNotinoReservations({
						dateFrom: query.dateFrom,
						dateTo: query.dateTo,
						createdAtFrom: query.createdAtFrom,
						createdAtTo: query.createdAtTo,
						reservationStates: query.reservationStates,
						reservationPaymentMethods: query.reservationPaymentMethods,
						reservationCreateSourceType: query.reservationCreateSourceType,
						categoryFirstLevelIDs: query.categoryFirstLevelIDs,
						countryCode,
						page: query.page,
						order: query.order,
						limit: query.limit,
						search: query.search
					})
				)
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		} finally {
			setVisibleConfirmModalData(undefined)
		}
	}

	const columns: ColumnsType<TableDataItem> = [
		{
			title: t('loc:ID'),
			dataIndex: 'id',
			key: 'id',
			ellipsis: true,
			width: '8%',
			render: (_value, record) => {
				const firstThree = record.id.substring(0, 3)
				const lastThree = record.id.substring(record.id.length - 3)

				return <Tooltip title={record.id}>{`${firstThree}...${lastThree}`}</Tooltip>
			}
		},
		{
			title: t('loc:Názov salónu'),
			dataIndex: 'salon',
			key: 'salonName',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(query.order, 'salonName'),
			width: '12%',
			render: (_value, record) => {
				return record.salon.deletedAt ? (
					<Tooltip title={t('loc:Salón bol vymazaný')}>
						<div className={'text-notino-gray'}>{record.salon.name}</div>
					</Tooltip>
				) : (
					<div className={'truncate'}>{record.salon.name}</div>
				)
			}
		},
		{
			title: t('loc:Dátum vytvorenia'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(query.order, 'createdAt'),
			width: '12%'
		},
		{
			title: t('loc:Dátum rezervácie'),
			dataIndex: 'startDate',
			key: 'startDate',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(query.order, 'startDate'),
			width: '8%'
		},

		{
			title: t('loc:Trvanie'),
			dataIndex: 'time',
			key: 'time',
			ellipsis: true,
			width: '8%'
		},
		{
			title: t('loc:Zákazník'),
			dataIndex: 'customer',
			key: 'customer',
			ellipsis: true,
			width: '12%',
			render: (_value, record) => {
				return (
					<div title={record.customer.name} className={'flex items-center'}>
						<UserAvatar
							style={record.customer.deletedAt || record.deletedAt ? { filter: 'grayscale(100)', opacity: 0.75 } : undefined}
							className='mr-2-5 w-7 h-7'
							src={record.customer.thumbnail}
							fallBackSrc={record.customer.originalImage}
						/>
						{record.customer.deletedAt ? (
							<Tooltip title={t('loc:Priradený zákazník je vymazaný zo salónu')}>
								<div className={'text-notino-gray truncate'}>{record.customer.name}</div>
							</Tooltip>
						) : (
							<div className={'truncate'}>{record.customer.name}</div>
						)}
					</div>
				)
			}
		},
		{
			title: t('loc:Služba'),
			dataIndex: 'service',
			key: 'service',
			ellipsis: true,
			width: '12%',
			render: (_value, record) => {
				return (
					<div title={record.service.name} className={'flex items-center'}>
						<UserAvatar
							style={record.service.deletedAt || record.deletedAt ? { filter: 'grayscale(100)', opacity: 0.75 } : undefined}
							className='mr-2-5 w-7 h-7'
							src={record.service.thumbnail}
							fallBackSrc={record.service.originalImage}
						/>
						{record.service.deletedAt ? (
							<Tooltip title={t('loc:Priradená služba je vymazaná zo salónu')}>
								<div className={'text-notino-gray truncate'}>{record.service.name}</div>
							</Tooltip>
						) : (
							<div className={'truncate'}>{record.service.name}</div>
						)}
					</div>
				)
			}
		},
		{
			title: t('loc:Zamestnanec'),
			dataIndex: 'employee',
			key: 'employee',
			ellipsis: true,
			width: '12%',
			render: (_value, record) => {
				return (
					<div title={record.employee.name} className={'flex items-center'}>
						<UserAvatar
							style={record.employee.deletedAt || record.deletedAt ? { filter: 'grayscale(100)', opacity: 0.75 } : undefined}
							className='mr-2-5 w-7 h-7'
							src={record.employee.thumbnail}
							fallBackSrc={record.employee.originalImage}
						/>
						{record.employee.deletedAt ? (
							<Tooltip title={t('loc:Priradený kolega je vymazaný zo salónu')}>
								<div className={'text-notino-gray truncate'}>{record.employee.name}</div>
							</Tooltip>
						) : (
							<div className={'truncate'}>{record.employee.name}</div>
						)}
					</div>
				)
			}
		},
		{
			title: t('loc:Vytvoril'),
			dataIndex: 'createSourceType',
			key: 'createSourceType',
			ellipsis: true,
			width: '8%'
		},
		{
			title: t('loc:Stav'),
			dataIndex: 'state',
			key: 'state',
			ellipsis: true,
			width: '8%',
			render: (_value, record) => {
				if (!record.state) {
					return '-'
				}

				const { icon, text } = translateReservationState(record.state)

				return (
					<div title={text} className={'flex items-center'}>
						<div className={cx('mr-2 flex items-center w-4 h-4', { 'icon-grayslace': record.deletedAt, 'opacity-75': record.deletedAt })}>{icon}</div>
						<div className={'truncate'}>{text}</div>
					</div>
				)
			}
		},
		{
			title: t('loc:Spôsob úhrady'),
			dataIndex: 'paymentMethod',
			key: 'paymentMethod',
			ellipsis: true,
			width: '160px',
			render: (_value, record) => {
				if (!record.paymentMethod) {
					return '-'
				}

				const { icon, text } = translateReservationPaymentMethod(record.paymentMethod)

				return (
					<div title={text} className={'flex items-center'}>
						<div className={cx('mr-2 flex items-center w-4 h-4', { 'icon-grayslace': record.deletedAt, 'opacity-75': record.deletedAt })}>{icon}</div>
						<div className={'truncate'}>{text}</div>
					</div>
				)
			}
		},
		{
			title: t('loc:Dátum vymazania'),
			dataIndex: 'deletedAt',
			key: 'deletedAt',
			ellipsis: true,
			width: '8%',
			render: (value) => value || '-'
		},
		{
			dataIndex: 'context-action',
			key: 'context-action',
			width: '50px',
			align: 'center',
			fixed: 'right',
			render: (_value, record) => {
				return (
					<div className={'flex-center '}>
						<Dropdown
							menu={{
								className: 'shadow-md flex flex-col gap-2',
								items: actionItems(record)
							}}
							placement='bottomRight'
							trigger={['click']}
						>
							<button className={'hover:bg-notino-grayLight p-1 rounded'} type={'button'} onClick={(e) => e.stopPropagation()}>
								<DotsIcon className={'w-4 h-4 rotate-90'} />
							</button>
						</Dropdown>
					</div>
				)
			}
		}
	]

	const breadcrumbs: IBreadcrumbs = {
		items: [
			{
				name: t('loc:Zoznam rezervácií')
			}
		]
	}

	return (
		<>
			<ConfirmModal
				okText={STRINGS(t).cancel(t('loc:rezerváciu'))}
				cancelText={t('loc:Ísť späť')}
				title={STRINGS(t).cancel(t('loc:rezerváciu'))}
				disabled={isCancelFormSubmitting}
				loading={isCancelFormSubmitting}
				onOk={() => dispatch(submit(FORM.CANCEL_RESERVATION_CONFIRM))}
				open={!!visibleConfirmModalData}
				onCancel={() => setVisibleConfirmModalData(undefined)}
				destroyOnClose
				maskClosable={false}
				zIndex={2000}
			>
				<CancelReservationConfirmForm onSubmit={handleCancelReservationConfirmForm} />
			</ConfirmModal>
			<Row>
				<Breadcrumbs breadcrumbs={breadcrumbs} backButtonPath={t('paths:index')} />
			</Row>
			<Row gutter={ROW_GUTTER_X_DEFAULT}>
				<Col span={24}>
					<Spin spinning={loading}>
						<div className='content-body'>
							<NotinoReservationsFilter onSubmit={handleSubmit} query={{ ...query, countryCode }} loading={loading} />
							<CustomTable<TableDataItem>
								className='table-fixed'
								columns={columns}
								onChange={onChangeTable}
								dataSource={notinoReservations?.tableData}
								rowClassName={(record) => cx({ 'noti-table-row-shadow': record.deletedAt, 'clickable-row': !record.deletedAt && !record.salon.deletedAt })}
								onRow={(record) => ({
									onClick: () => {
										if (!record.deletedAt && !record.salon.deletedAt) {
											const redirectQuery = {
												view: CALENDAR_VIEW.DAY,
												date: dayjs(record.startDate, DEFAULT_DATE_INPUT_FORMAT).format(CALENDAR_DATE_FORMAT.QUERY),
												sidebarView: CALENDAR_EVENT_TYPE.RESERVATION,
												eventId: record.key
											}

											navigate({
												pathname: t('paths:salons/{{salonID}}/calendar', { salonID: record.salon.id }),
												search: formatObjToQuery(redirectQuery)
											})
										}
									}
								})}
								twoToneRows
								scroll={{ x: 1400 }}
								useCustomPagination
								pagination={{
									pageSize: notinoReservations.data?.pagination.limit,
									total: notinoReservations.data?.pagination.totalCount,
									current: notinoReservations.data?.pagination.page,
									onChange: onChangePagination,
									disabled: notinoReservations.isLoading
								}}
							/>
						</div>
					</Spin>
				</Col>
			</Row>
		</>
	)
}

export default compose(withPermissions([PERMISSION.NOTINO]))(NotinoReservationsPage)
